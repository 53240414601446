.container {
  margin: 0 5rem;
}

.badge {
  display: ruby;
  justify-content: center;
  align-items: center;
  padding: 12px 23px;
  gap: 10px;
  border-radius: 100px;
  border: 1px solid #0a47c9;
}

.badge h1 {
  color: #0a47c9;
  text-align: center;
  font-family: Poppins, sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 120%; /* 28.8px */
}

.paraDiv {
  width: 83vw;
  margin-top: 28px;
}

.para {
  color: #000;
  font-family: Poppins, sans-serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 154%; /* 49.28px */
  text-align: start;
}
.scrollToTop {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #0a47c9;
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 24px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s;
}

.scrollToTop:hover {
  background-color: #083f9d;
}

.content {
  max-height: 600px; /* Adjust height as needed */
  min-height: 200px; /* Ensure there's always a height */
  overflow-y: auto; /* Ensure vertical scrolling is enabled */
  margin-top: 64px;
  scrollbar-width: thin; /* For Firefox: show a thin scrollbar */
  scrollbar-color: #ca2a2a #f0f0f0; /* For Firefox: scrollbar thumb and track color */
  direction: ltr; /* Set left-to-right direction */
}

/* Webkit browsers (Chrome, Safari, Opera) */
.content::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.content::-webkit-scrollbar-track {
  background: #f0f0f0; /* Background color of the track */
}

.content::-webkit-scrollbar-thumb {
  background-color: #ffff; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners for the scrollbar thumb */
}
.industry {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ddd; /* Simple border to separate items */
  transition: background-color 0.3s, transform 0.3s;
  align-items: center;
  padding: 2vw;
  border-radius: 2px;
}

.industry:hover {
  background-color: #0a47c9;
  color: #fff; /* Change text color to white on hover */
}

.industry:hover .title,
.industry:hover .description {
  color: #fff; /* Change text color to white on hover */
}

.industry:hover {
  transform: translateY(-10px); /* Animation effect */
}

.title {
  color: #010202;
  font-family: Poppins, sans-serif;
  font-size: 2.4vw;
  font-weight: 400;
  line-height: 100%; /* 49.116px */
  letter-spacing: 0.491px;
  width: 50%; /* Occupy 50% of the width */
}

.description {
  color: #303030;
  font-family: Poppins, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.205px;
  width: 50%; /* Occupy 50% of the width */
}

.divider {
  margin: 10px 0;
  border: 0;
  border-top: 1px solid #ddd;
}

@media (max-width: 1024px) {
  .industry {
    padding: 20px 0px;
  }
  .title,
  .description {
    font-size: 1.8vw; /* Adjust font size for medium screens */
  }
}

@media (max-width: 768px) {
  .badge {
    display: flex;
    justify-content: center;
  }
  .paraDiv {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .container {
    margin: 40px 24px;
  }
  .industry {
    flex-direction: column;
    align-items: center;
    padding: 1rem;
  }

  .title,
  .description {
    width: 100%; /* Full width on smaller screens */
    font-size: 4vw; /* Adjust font size for small screens */
  }

  .description {
    margin-top: 10px;
  }
  .content {
    margin-top: 16px;
  }
}

@media (max-width: 576px) {
  .container {
    margin: 40px 24px;
  }
  .badge {
    width: fit-content;
    padding: 5px 9px;
    margin: 0 auto;
    border-radius: 43.306px;
    border: 0.433px solid #0a47c9;
  }
  .badge h1 {
    font-size: 14px;
    line-height: 120%; /* 9.6px */
  }

  .para {
    text-align: center;
    font-size: 10px;
    line-height: 134%; /* 10.72px */
  }

  .title {
    font-size: 16px;
    line-height: 100%; /* 12px */
    letter-spacing: 0.12px;
  }

  .description {
    font-size: 10px;
    line-height: 15px;
    letter-spacing: -0.06px;
  }
}
