.faqContainer {
  display: flex;
  flex-wrap: wrap;
  margin: 80px 80px 80px 160px;
  justify-content: space-between;
  gap: 10px; /* Adjust gap as needed to fit columns in one row */
}

.leftColumn {
  flex: 0 0 45%; /* Adjusted width to fit within the container */
}

.rightColumn {
  flex: 0 0 45%; /* Adjusted width to fit within the container */
}

.faqHeader {
  text-align: left;
  margin-bottom: 10px;
  color: #010205;
  font-family: Poppins, sans-serif;
  font-size: 48px;
  font-weight: 600;
  line-height: 130%; /* 62.4px */
  letter-spacing: -1.44px;
}

.faqPara {
  text-align: left;
  margin-bottom: 20px;
  color: #878c91;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 180%; /* 28.8px */
}

.contactButton {
  display: flex;
  width: 176px;
  padding: 17px 16px;
  justify-content: center;
  align-items: center; /* Center text vertically */
  border: 1px solid #0a47c9;
  color: #0a47c9;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 140%; /* 22.4px */
  letter-spacing: -0.32px;
  background-color: #fff;
  border-radius: 50px;
}

.faqItem {
  margin-bottom: 10px;
}

.faqQuestion {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 10px;
  color: #0a47c9;
  font-family: Poppins, sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 150%; /* 36px */
  letter-spacing: -0.72px;
}

.faqAnswer {
  padding: 10px;
  border-top: none;
  color: #878c91;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 180%; /* 28.8px */
}

hr {
  border: none;
  border-top: 1px solid #ddd;
  margin: 10px 0;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .faqContainer {
    margin: 80px 20px; /* Adjust margin for medium screens */
    gap: 20px; /* Increase gap slightly for medium screens */
  }

  .leftColumn,
  .rightColumn {
    flex: 1 1 100%; /* Stack columns vertically on medium screens */
  }
}

@media (max-width: 768px) {
  .faqContainer {
    margin: 40px 24px; /* Adjust margin for smaller screens */
    gap: 10px; /* Reduce gap for smaller screens */
  }

  .faqHeader {
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 134%; /* 26.8px */
  }

  .faqPara {
    color: #878c91;
    text-align: center;
    font-family: Poppins;
    font-size: 8px;
    font-style: normal;
    font-weight: 400;
    line-height: 134%; /* 10.72px */
  }

  .faqQuestion {
    font-size: 18px; /* Reduce font size for FAQ questions */
  }

  .faqAnswer {
    font-size: 14px; /* Reduce font size for FAQ answers */
  }
  .contactBtnDiv {
    /* width: 86px; */
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 480px) {
  .faqHeader {
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 134%; /* 26.8px */
  }

  .faqPara {
    margin: 0 24px;
    color: #878c91;
    text-align: center;
    font-family: Poppins;
    font-size: 8px;
    font-style: normal;
    font-weight: 400;
    line-height: 134%; /* 10.72px */
  }
  .contactBtnDiv {
    display: flex;
    justify-content: center;
  }
  .contactButton {
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 24px;
    border: 0.48px solid #0a47c9;
    color: #0a47c9;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 14px */
    letter-spacing: -0.2px;
    margin-top: 16px;
  }

  .faqQuestion {
    font-size: 16px; /* Further reduce font size for FAQ questions */
  }

  .faqAnswer {
    font-size: 12px; /* Further reduce font size for FAQ answers */
  }
}
