/* Navbar.module.css */
.navContainer {
  position: relative;
}

.navbar {
  width: 100%;
  height: 6.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5rem;
  background-color: #0a47c9;
  box-shadow: 0 0.25rem 0.375rem rgba(0, 0, 0, 0.1);
  z-index: 10;
}
.hrLine {
  width: 200px;
  border: 1px solid #fff;
}
.navItemText {
  color: #fff;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 44.8px */
  letter-spacing: -0.64px;
}

.navItemSubText {
  color: #fff;
  font-family: Poppins;
  font-size: 24px;
  font-style: italic;
  font-weight: 400;
  line-height: 140%; /* 33.6px */
  letter-spacing: -0.48px;
}

a {
  text-decoration: none;
  color: #fff;
}

.logo img {
  height: 2.5rem;
  max-height: 80%;
}

.menu {
  display: flex;
  align-items: center;
}

.getStarted {
  margin-right: 2rem;
  font-size: 1.5rem;
  text-decoration: none;
  color: #fff;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4;
}

.arrow {
  margin-left: 0.5rem;
}

.hamburger {
  margin-left: 2rem;
  cursor: pointer;
}

.hamburger img {
  height: 2.5rem;
  width: 2.5rem;
}

.expandedMenu {
  position: absolute; /* Position it above the rest of the content */
  top: 6.25rem; /* Align it below the navbar */
  width: 100%;
  background-color: #0a47c9;
  transform: translateY(-100%);
  transition: transform 0.3s ease-in-out, max-height 0.3s ease-in-out;
  overflow: hidden;
  max-height: 0;
  height: auto;
  z-index: 9;
}

.open {
  transform: translateY(0);
  max-height: fit-content;
}

.expandedContent {
  display: flex;
  justify-content: space-between;
  padding: 0rem 5rem;
}

.innerNavItemDiv {
  margin-left: 20px;
}

.expandedLogo {
  height: 2rem;
  margin-bottom: 1rem;
}

.left ul {
  list-style: none;
  padding: 0;
  margin-top: 28px;
}

.left ul li {
  position: relative;
  margin-top: 22px;
}

.left ul li.topLevel {
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: -0.48px;
}

.left ul li.subItem {
  padding-left: 20px; /* Example indentation for sub-items */
}

.left ul li hr {
  border: 0;
  border-top: 1px solid #fff;
  margin: 1rem 0;
}

.right {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
}

.imagePreview {
  height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imagePreview img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.getStartedMobile {
  display: none;
}

@media (max-width: 768px) {
  .navContainer {
    position: relative;
  }

  .navbar {
    padding: 0 1rem;
  }

  .menu {
    margin-left: 1rem;
  }

  .hamburger img {
    height: 1.5rem;
    width: 1.5rem;
  }

  .expandedContent {
    flex-direction: column;
    padding: 1rem;
  }

  .left ul {
    margin-top: 1rem;
  }

  .right {
    margin-top: 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .expandedMenu.open {
    max-height: 100vh;
  }

  .getStartedMobile {
    display: block;
    margin-top: 1rem;
  }

  .getStarted {
    display: none;
  }
}
