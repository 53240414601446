/* Base styles for desktop */
* {
  box-sizing: border-box;
}

.blogDetailsContainer {
  display: flex;
  flex-direction: column;
  margin: 100px 80px 0px 80px;
}

.blogImageContainer {
  position: relative;
}

.blogImage {
  width: 100%;
  height: 600px;
  flex-shrink: 0;
}

.blogInfo {
  width: 100%;
  position: absolute;
  bottom: 4px;
  color: white;
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(28.893051147460938px);
  padding: 0 10px;
}

.blogTitle {
  margin: 12px 0 16px 0;
  color: var(--White, #fff);
  font-family: Poppins;
  font-size: 37px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.blogMeta {
  display: flex;
  gap: 20px;
  margin-bottom: 67px;
}

.readingTime,
.datePosted {
  color: var(--White, #fff);
  font-family: Poppins;
  font-size: 23.114px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.blogContent {
  display: flex;
  margin-top: 20px;
  gap: 22px;
  justify-content: space-between;
}

.articleSection {
  width: 60%;
}

.section {
  margin-bottom: 49px;
}

.subTitle {
  color: var(--Type-color, #1b1b1f);
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.paragraph {
  margin-top: 33px;
  color: var(--Type-color, #1b1b1f);
  font-family: Poppins;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-align: left;
}

.articleHead {
  color: var(--Type-color, #1b1b1f);
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.articleNav {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: end;
}

.articleNavList {
  list-style: none;
  padding: 0;
  margin-top: 27px;
}

.navItem {
  margin-bottom: 30px;
}

.navLink {
  font-size: 16px;
  color: var(--Type-color, #1b1b1f);
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  text-align: right;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.activeNavLink {
  color: blue; /* Highlight color for active nav item */
}

.hrLine {
  border: 1.6px solid #6c7880;
}

/* Responsive styles */
@media (max-width: 1200px) {
  .blogDetailsContainer {
    margin: 80px 60px 0 60px;
  }

  .blogImage {
    height: 500px; /* Adjusted height */
  }

  .blogTitle {
    font-size: 2rem;
  }

  .blogMeta span {
    font-size: 1rem;
  }

  .articleNav {
    display: none; /* Hide article navigation on medium screens */
  }
}

@media (max-width: 768px) {
  .blogDetailsContainer {
    margin: 60px 40px 0 40px;
  }

  .blogImage {
    height: 400px; /* Adjusted height */
  }

  .blogTitle {
    font-size: 1.5rem;
  }

  .blogMeta {
    flex-direction: row;
    gap: 11px;
  }

  .blogMeta span {
    font-size: 0.875rem;
  }

  .paragraph {
    font-size: 0.875rem;
  }

  .subTitle {
    font-size: 1.5rem;
  }

  .articleHead {
    font-size: 1.5rem;
  }

  .navLink {
    font-size: 0.875rem;
  }

  .articleSection {
    width: 100%; /* Full width on smaller screens */
  }

  .blogContent {
    flex-direction: column; /* Stack sections vertically on small screens */
  }
}

@media (max-width: 480px) {
  .blogDetailsContainer {
    margin: 40px 20px 0 20px;
  }

  .blogImage {
    height: 300px; /* Adjusted height */
  }

  .blogTitle {
    font-size: 1.25rem;
  }

  .blogMeta {
    font-size: 0.75rem;
    margin-bottom: 10px;
  }

  .paragraph {
    font-size: 0.75rem;
  }

  .subTitle {
    font-size: 1.25rem;
  }

  .articleHead {
    font-size: 1.25rem;
  }

  .navLink {
    font-size: 0.75rem;
  }
}
