.sliderContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden; /* Hide overflowing content */
  margin-left: 5vw;
  margin-right: 5vw;
  height: 30vw;
}

.sliderWrapper {
  width: 100%;
  overflow: hidden;
}

.sliderContent {
  display: flex;
  width: 100%;
  transition: transform 0.5s ease; /* Smooth sliding transition */
}

.videoWrapper {
  position: relative;
  min-width: 100%;
  height: 560px;
}

.video {
  width: 100%;
  height: 100%;
  object-fit: cover; 
}

.videoOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    lightgray 10% / cover no-repeat;
}

.playButtonContainer {
  position: absolute;
  top: 36%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.playButton {
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  height: 40px;
  width: 40px;
}

.indicators {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  gap: 1rem;
}

.indicator {
  width: 4vw;
  height: 1vh;
  flex-shrink: 0;
  border-radius: 20px;
  background: rgba(10, 71, 201, 0.3);
}

.indicator.active {
  background-color: #333;
  width: 8vw;
  height: 1vh;
  flex-shrink: 0;
  border-radius: 20px;
  background: #0a47c9;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .sliderContainer {
    margin-left: 40px; /* Reduce side margins */
    margin-right: 40px;
    height: auto; /* Adjust height for smaller screens */
  }

  .videoWrapper {
    height: 300px; /* Adjust video height */
  }

  .playButton {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.8);
    border: none;
    border-radius: 50%;
    font-size: 2rem;
    cursor: pointer;
  }
  /* .playButtonContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  } */
}

@media (max-width: 480px) {
  .sliderContainer {
    margin-left: 24px;
    margin-right: 24px;
    height: auto; /* Adjust height for smaller screens */
  }

  .videoWrapper {
    height: 200px; /* Further adjust video height */
  }

  .playButton {
    height: 25px;
    width: 25px;
  }

  .indicators {
    margin-top: 16px;
    gap: 1rem;
  }

  .indicator {
    width: 12px;
    height: 4px;
    border-radius: 4px;
  }

  .indicator.active {
    width: 24px;
    height: 4px;
    border-radius: 4px;
  }
}

.playButton {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 50%;
  font-size: 2rem;
  cursor: pointer;
}

/* Slider.module.css */

.sliderContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  margin-left: 4vw;
  margin-right: 4vw;
  height: 30rem;
}

.sliderWrapper {
  width: 100%;
  overflow: hidden;
}

.sliderContent {
  display: flex;
  width: 100%;
  transition: transform 0.5s ease;
}

.videoWrapper {
  position: relative;
  min-width: 100%;
  height: 560px;
}

.video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.videoOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );
}

.playButtonContainer {
  position: absolute;
  top: 36%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.playButton {
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  height: 40px;
  width: 40px;
}

.indicators {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  gap: 1rem;
}

.indicator {
  width: 4vw;
  height: 1vh;
  border-radius: 20px;
  background: rgba(10, 71, 201, 0.3);
}

.indicator.active {
  background: #0a47c9;
}

@media (max-width: 768px) {
  .sliderContainer {
    margin-left: 40px;
    margin-right: 40px;
    height: auto;
  }

  .videoWrapper {
    height: 300px;
  }

  .playButton {
    height: 30px;
    width: 30px;
  }

  .playButtonContainer {
    top: 50%;
  }
}

@media (max-width: 480px) {
  .sliderContainer {
    margin-left: 24px;
    margin-right: 24px;
    height: auto;
  }

  .videoWrapper {
    height: 200px;
  }

  .playButton {
    height: 25px;
    width: 25px;
  }

  .indicators {
    margin-top: 16px;
    gap: 0.5rem;
  }

  .indicator {
    width: 12px;
    height: 4px;
  }

  .indicator.active {
    width: 24px;
    height: 4px;
  }
}
