.container {
  position: relative;
  overflow-y: auto; /* Allow vertical scrolling */
  max-height: 150vh;
  margin: 0px 4vw 0px 4vw;
}

.serviceContainer {
  margin-bottom: 40px;
  border-radius: 8px;
  padding-right: 6vw;
}

.serviceHeader {
  margin-bottom: 10px;
}

.name {
  color: #0a47c9;
  font-family: Poppins;
  font-size: 3.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.description {
  color: #000;
  font-family: Poppins;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 33.6px */
}

.serviceImage {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.headerContainer {
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
  margin-bottom: 24px;
}

.cardsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  row-gap: 48px;
}

.cardDescription {
  color: #808080;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 151.523%; /* 27.274px */
}

.card {
  flex: 1;
  min-width: calc(50% - 20px);
  border-radius: 8px;
}

.cardIcon {
  width: 48px;
  height: 48px;
}

.cardHeader {
  color: #2d2d2d;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.contactButton {
  margin-top: 48px;
  cursor: pointer;
  display: flex;
  width: 176px;
  padding: 17px 16px;
  justify-content: center;
  align-items: flex-end;
  gap: 32px;
  border-radius: 50px;
  border: 1px solid #0a47c9;
  color: #0a47c9;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 22.4px */
  letter-spacing: -0.32px;
  background-color: #fff;
}

/* .contactButton:hover {
  background-color: #0056b3;
  border: 1px solid black;
} */

/* Custom scrollbar styles */
.container::-webkit-scrollbar {
  width: 3px;
}

.container::-webkit-scrollbar-thumb {
  background: #ee7c00;
  width: 1px;
  border-radius: 10px;
}

.container::-webkit-scrollbar-track {
  background-color: #0056b3;
}

/* Responsive Design */
@media (max-width: 1200px) {
  .name {
    font-size: 3rem;
  }

  .description {
    font-size: 1rem;
  }

  .cardHeader {
    font-size: 22px;
  }

  .cardDescription {
    font-size: 13px;
  }
}

@media (max-width: 992px) {
  .container {
    margin: 100px 40px 0px 40px;
  }

  .name {
    font-size: 2.5rem;
  }

  .description {
    font-size: 0.95rem;
  }

  .cardHeader {
    font-size: 20px;
  }

  .cardDescription {
    font-size: 12px;
  }

  .contactButton {
    width: 150px;
    padding: 14px 12px;
  }
}

@media (max-width: 768px) {
  .container {
    margin: 2vh;
  }

  .serviceContainer {
    width: 100%;
    padding: 0 2vh 0 0;
  }

  .cardsContainer {
    flex-direction: column;
  }

  .card {
    min-width: 100%;
  }

  .name {
    font-size: 2rem;
  }

  .description {
    font-size: 0.9rem;
  }

  .cardHeader {
    font-size: 18px;
  }

  .cardDescription {
    font-size: 11px;
  }

  .contactButton {
    width: 130px;
    padding: 12px 10px;
  }
}

@media (max-width: 576px) {
  .container {
    margin: 2vh;
  }

  .name {
    font-size: 1.5rem;
  }

  .description {
    font-size: 0.8rem;
  }

  .cardHeader {
    font-size: 16px;
  }

  .cardDescription {
    font-size: 10px;
  }

  .contactButton {
    width: 110px;
    padding: 10px 8px;
  }
}
