.dimensionContainer {
  margin-top: 80px;
}

.wrapperBorder {
  border-radius: 30px;
  border: 1px solid #000;
  background: #fff;
  margin: 0rem 5rem;
  padding: 5rem;
  background-image: none;
  background: none;
}

/* Flexbox layout for rows */
.row {
  display: flex;
  flex-wrap: wrap;
  gap: 6rem;
}

.imageCol,
.dimensionCol {
  flex: 1 1;
  width: 608px;
  flex-shrink: 0;
  flex-direction: column;
}

.image {
  width: 100%;
  height: auto;
}

.dimensionTable {
  width: 100%;
  border-collapse: collapse;
}

.dimensionTable td {
  border: 1px solid #ddd;
  padding: 8px;
}

.header {
  color: #2d2d2d;
  text-align: center;
  font-family: "Plus Jakarta Sans";
  font-size: 2.6rem;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 62.4px */
  letter-spacing: -1.44px;
  margin-top: 62px;
}

.tabContainer {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 5rem;
}

.tabButton {
  height: 36px;
  width: 14rem;
  padding: 13px 16px;
  /* width: fit-content; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  border: 1px solid #0a47c9;
  background-color: #fff;
  cursor: pointer;
  text-wrap: nowrap;
  font-size: 13px;
}

.tabButton:hover {
  background: #0a47c9;
  color: #fff;
}

.activeTab {
  height: 36px;
  width: 14rem;
  color: #fff;
  background: #0a47c9;
  /* color: #0a47c9; */
  font-family: Manrope;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 19.6px */
  letter-spacing: -0.28px;
  text-wrap: nowrap;
  padding: 13px 16px;
}

/* Responsive design */
/* @media (max-width: 1024px) {
  .wrapperBorder {
    margin: 0 24px;
    padding: 12px 24px;
    background: #001664 0% 0% / 16.95667654275894px 16.95667654275894px repeat;
    backdrop-filter: blur(6.9454545974731445px);
  }

  .imageCol,
  .dimensionCol {
    width: 100%;
  }
} */

@media (max-width: 768px) {
  .wrapperBorder {
    margin: 0 24px;
    padding: 12px 24px;
    background: #001664 0% 0% / 16.95667654275894px 16.95667654275894px repeat;
    backdrop-filter: blur(6.9454545974731445px);
    color: #000;
  }
  .row {
    flex-direction: column;
    gap: 1rem; /* Adjust gap for smaller screens */
  }

  .imageCol,
  .dimensionCol {
    width: 100%;
  }
  .dimensionTable td {
    border: 1px solid #ddd;
    padding: 8px;
    /* color: #0a0505; */
    color: #fff;
  }
  .header {
    display: none;
    width: 350px;
    height: 39px;
    flex-direction: column;
    justify-content: center;
    font-size: 2rem; /* Adjust font size for smaller screens */
    margin-top: 40px;

    color: #000;
    text-align: center;
    /* leading-trim: both;
    text-edge: cap; */
    font-family: "Plus Jakarta Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 20.8px */
    letter-spacing: -0.48px;
  }

  .tabContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 24px;
    margin-top: 2rem;
  }
  .tabButton {
    width: fit-content;
    width: 100%;
    flex-direction: row;
    padding: 10px 12px;

    /* display: flex; */
    /* width: 81.299px;
    padding: 4.478px 5.512px;
    justify-content: center;
    align-items: flex-end;
    gap: 11.024px;
    border-radius: 17.224px;
    border: 0.344px solid #fff; */
  }
}
