.container {
  margin-top: 2vw;
  padding: 4vw;
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 80px;
}

.blankCol {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
}

.iconTextWrapper {
  width: 70%;
  display: flex;
  align-items: center;
  gap: 10px; /* Space between icon and text */
  color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */
}

.icon {
  width: 40px; /* Adjust the size of the icon */
  height: 40px;
  background-color: #0a47c9; /* Example background color */
  border-radius: 50%; /* Make the icon circular */
}

.text {
  color: #0a47c9; /* Example text color */
  font-family: Poppins, sans-serif;
  font-size: 1.5rem;
  font-weight: 500;
}

.contentCol {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.badge {
  display: inline-flex;
  padding: 0.75rem 1.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 2rem;
  border: 1px solid #0a47c9;
  color: #0a47c9;
  text-align: center;
  font-family: Poppins, sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.2;
}

.headerDiv {
  display: flex;
  align-items: center;
  margin-top: 3rem;
  width: 100%;
}

.header {
  margin: 0;
  color: #000;
  font-family: Poppins, sans-serif;
  font-size: calc(2.65vw + 4px);
  font-weight: 600;
  line-height: 4vw;
  width: 65%;
}

.description {
  font-size: calc(0.75vw + 4px);
  width: 35%;
}

.imageContainer {
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  margin-top: 2.5rem;
  flex-wrap: wrap;
  width: 100%;
}

.imageWrapper {
  position: relative;
  display: flex;
  width: 20vw;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 1rem;
  background: #2d2d2d;
  overflow: hidden;
}

.image {
  width: 100%;
  height: 100%;
  opacity: 0.7;
  transition: opacity 0.3s;
}

.imageWrapper:hover .image {
  opacity: 0.5;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2vw;
  padding: 0vw;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s;
}

.imageWrapper:hover .overlay {
  opacity: 1;
}

.hoverHead {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: calc(1.5vw + 4px);
  padding: 0 2vw;
  font-weight: 500;
}

.hoverText {
  color: white;
  padding: 4px 8px;
  text-align: center;
  font-size: calc(.8vw + 2px);
  line-height: calc(1vw + 4px);
}

/* @media (min-width: 768px) {
  .headerDiv {
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
  }

  .header {
    font-size: 3vw;
  }
} */

@media (max-width: 768px) {
  .container {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 56px;
    padding: 0 24px;
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 0px;
  }

  .blankCol {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .contentCol {
    width: 100%;
    align-items: center;
  }

  .badge {
    margin-top: 40px;
    font-size: 1.2rem;
    padding: 5px 9px;
  }

  .headerDiv {
    flex-direction: column;
    text-align: center;
    width: 100%;
    margin-top: 13px;
  }

  .header {
    width: 100%;
    margin: 0 24px;
    font-size: 20px;
    line-height: 28px;
  }

  .description {
    width: 100%;
    margin-top: 2vw;
    font-size: 10px;
    line-height: 15px;
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4vw;
    padding: 0vw;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: opacity 0.3s;
  }

  .hoverHead {
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: calc(1.5vw + 12px);
    padding: 0 2vw;
    font-weight: 500;
  }
  
  .hoverText {
    color: white;
    padding: 4px 8px;
    text-align: center;
    font-size: calc(1vw + 8px);
    line-height: calc(1.5vw + 12px);
  }

  .imageContainer {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    margin-top: 2rem;
  }

  .imageWrapper {
    width: 80vw;
    height: 280px;
  }
}
