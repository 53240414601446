.container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;
  position: relative;
  min-height: 240px;
  margin: 4vh 5vw 4px 5vw;
}

.textContainer {
  position: absolute;
  opacity: 0;
  animation: fadeInOut 10s infinite;
  display: flex;
  align-items: center;
  width: 100%;
  height: full;
  box-sizing: border-box;

}

.left {
  justify-content: space-between;
  flex-direction: row;
  gap: 5vw;
}

.center {
  width: 100vw;
  flex-direction: column;
  text-align: center;
}

.right {
  justify-content: space-between;
  flex-direction: row-reverse;
  /* gap: 120px; */
  text-align: end;
  /* height: max-content; */
}

.bigText {
  width: 50vw; /* Adjusted width for the first big text */
  color: var(--Color-Black, #000);
  font-family: Poppins;
  font-size: 4vw;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 100.8px */
}

.secondTextBig {
  /* width: 1606px; */
  /* max-height: 195px;  */
  overflow: hidden;
  text-overflow: ellipsis; /* Optional: shows "..." for overflow text */
  white-space: normal; /* Allows wrapping */
  line-height: 1.2; /* Adjust line height if needed */
}

.smallText {
  font-size: 17px; /* Adjusted font size */
  width: 40vw; /* Adjusted width for the second small text */
  color: #6b6b6b;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 28px */
}

.firstTextSmall {
  align-self: flex-end;
  width: 35vw; /* Adjusted width for the small text */
}
.thirdTextSmall {
  align-self: flex-end;
  text-align: left;
  width: 490px; /* Adjusted width for the small text */
}

.secondTextMargin {
  margin-top: 20px; /* Adjust this value as needed */
  margin-bottom: 20px; /* Adjust this value as needed */
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* Responsive styles */
@media (max-width: 768px) {
  .container {
    margin: .5rem; /* Adjust margin as needed */
    /* margin-top: 0; */
    text-align: center;
    display: flex;
    min-height: 120px;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .smallText {
    font-size: 11px;
  }
  .bigText,
  .smallText {
    width: 100%; /* Occupy full width */
  }

  .bigText {
    width: 90vw; /* Set the width of the big text area */
    color: var(--Color-Black, #000);
    text-align: center;
    font-family: Poppins;
    font-size: 20px; /* Set the font size */
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 24px */
  }

  /* .smallText {
    font-size: 4vw;
  } */

  .textContainer {
    flex-direction: column; /* Stack texts vertically */
    align-items: center; /* Center-align texts */
  }
  /* .secondTextSmall, */
  .firstTextSmall,
  .thirdTextSmall {
    align-self: center;
    color: #6b6b6b;
    text-align: center;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 14px */
  }
  .secondTextMargin {
    /* width: 100%; */
    margin-top: 20px; /* Adjust this value as needed */
    margin-bottom: 20px; /* Adjust this value as needed */
  }
  .left,
  .right {
    flex-direction: column; /* Adjust for smaller devices */
    gap: 8px; /* Reduced gap for smaller devices */
  }

  .center {
    width: 100%;
    flex-direction: column;
    text-align: center;
  }
}

@media (min-width: 768px) and (max-width: 1068px) {
  .container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow: hidden;
    position: relative;
    height: 20vw;
    /* margin: 2.5rem 5rem 1rem 5rem;  */
    margin: 2vh 5vw -3vh 5vw;
  }
}
@media (min-width: 1750px) {
  .container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow: hidden;
    position: relative;
    height: 20vw;
    /* margin: 2.5rem 5rem 1rem 5rem;  */
    margin: 4vh 5vw -8vh 5vw;
  }
}
